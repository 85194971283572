import React from 'react';
import styled from 'styled-components';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Image from 'components/Image';
import { device } from 'styles/theme';

export type FeatureImagesProps = {
  id: string;
  internal: {
    type: 'ContentfulComponentFeatureImages';
  };
  leftImage: {
    gatsbyImageData: IGatsbyImageData;
  };
  rightImage: {
    gatsbyImageData: IGatsbyImageData;
  };
};

function FeatureImages({ leftImage, rightImage }: FeatureImagesProps) {
  return (
    <Container>
      <Image {...leftImage} />
      <Image {...rightImage} />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    ${Image} {
      grid-row: 1/4;
      &:nth-child(2) {
        grid-row: 2/5;
      }
    }
  }
`;

export default styled(FeatureImages)<FeatureImagesProps>``;
