import React from 'react';
import Image from 'components/Image';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { device } from 'styles/theme';

export type FeatureImagesGridProps = {
  id: string;
  internal: {
    type: 'ContentfulComponentFeatureImagesGrid';
  };
  images: {
    gatsbyImageData: IGatsbyImageData;
  }[];
};

function FeatureImagesGrid({ images }: FeatureImagesGridProps) {
  return (
    <ImageGrid>
      {images.map((image, i) => {
        return <Image key={i} {...image} />;
      })}
    </ImageGrid>
  );
}

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8vw;
  justify-content: space-between;
  ${Image} {
    max-width: 400px;
    margin: auto;
  }
  @media ${device.tablet} {
    ${Image} {
      max-width: unset;
    }
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    ${Image} {
      &:nth-child(3n + 2) {
        transform: translateY(-100px);
      }
      &:last-child {
        transform: none;
        grid-column: 3/4;
      }
    }
  }
`;

export default FeatureImagesGrid;
