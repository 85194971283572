import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

type ImageProps = {
  gatsbyImageData: IGatsbyImageData;
  alt?: string;
  className?: string;
};

const Image = ({ className, gatsbyImageData }: ImageProps) => {
  const isGatsbyImage = Boolean(gatsbyImageData?.images);
  const alt = 'An image uploaded to Strapi';
  if (isGatsbyImage) {
    return (
      <GatsbyImage
        className={className}
        imgStyle={{ objectFit: 'contain' }}
        image={getImage(gatsbyImageData) as IGatsbyImageData}
        alt={alt}
      />
    );
  }
  return null;
  // make image responsive
  // https://snowball.digital/blog/responsive-images-in-react-with-srcset
};

export default styled(Image)<ImageProps>`
  object-fit: contain;
`;
