import React from 'react';
import styled from 'styled-components';
import { SplitText } from 'gsap/all';
import gsap from 'gsap';
import { device } from 'styles/theme';
import { ContentfulComponent, ContentfulProject } from 'types';
import WrapperContainer from '@atoms/WrapperContainer';
import Image from 'components/Image';
import Layout from 'components/Layout';
import FeatureImage from 'components/organinsms/FeatureImage';
import FeatureImages from 'components/organinsms/FeatureImages';
import FeatureImagesGrid from 'components/organinsms/FeatureImagesGrid';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import ContactSection from 'components/organinsms/ContactSection';

// Map Contentful components to section components
const projectsComponents = {
  ContentfulComponentFeatureImages: FeatureImages,
  ContentfulComponentFeatureImagesGrid: FeatureImagesGrid,
  ContentfulComponentFeatureImage: FeatureImage,
};

// Display a section individually
const Section = (sectionData: ContentfulComponent) => {
  // Prepare the component
  const SectionComponent = projectsComponents[sectionData.internal.type];

  if (!SectionComponent) {
    // No matching component for this page section
    return null;
  }

  // Display the section
  return <SectionComponent {...(sectionData as any)} />;
};

function ProjectPageTemplate({
  title,
  mainImage,
  previewImage,
  fullProjectName,
  projectDescription,
  components,
}: ContentfulProject) {
  const mainTitleRef = React.useRef(null);

  useIsomorphicLayoutEffect(() => {
    const split = new SplitText(mainTitleRef.current, {
      type: 'chars,words',
    });
    gsap.from(split.chars, {
      // translateY: 100,
      // translateZ: 0,
      // opacity: 0,
      // easing: 'easeOutExpo',
      y: 200,
      skewY: 10,
      duration: 0.5,
      stagger: {
        amount: 0.5,
        ease: 'power1.out',
      },
    });
    gsap.to('.cover', {
      height: 0,
      ease: 'expo.inOut',
      duration: 2.4,
      scrollTrigger: {
        trigger: '.cover',
        start: 'top 90%',
      },
    });
  }, []);

  return (
    <Layout>
      <ProjectWrapperContainer>
        <ProjectHero>
          <Title ref={mainTitleRef}>
            <span>{title}</span>
          </Title>
          <ImageContainer>
            <PreviewImage {...previewImage} />
            <ImageCover className="cover" />
          </ImageContainer>
        </ProjectHero>
        <ProjectDescription>
          <DescriptionTitle>{fullProjectName}</DescriptionTitle>
          <DescriptionText>{projectDescription.projectDescription}</DescriptionText>
        </ProjectDescription>
        <MainImage {...mainImage} />
        {components.map(component => {
          return <Section key={component.id} {...component} />;
        })}
        <ContactSection />
      </ProjectWrapperContainer>
    </Layout>
  );
}

const ImageCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  top: 0;
  left: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  grid-row-start: 2;
  grid-column-start: 1;
  justify-self: center;
  align-self: flex-start;
  width: 90%;
  margin-top: 20px;
  max-height: 90vh;
  z-index: 1;
  @media ${device.mobileL} {
    width: 75%;
    margin-top: 24px;
  }
  @media ${device.tablet} {
    grid-row-start: 1;
    justify-self: end;
    width: 50%;
  }
`;

const PreviewImage = styled(Image)`
  width: 100%;
  max-height: 90vh;
  z-index: 1;
`;

const ProjectWrapperContainer = styled(WrapperContainer)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media ${device.tablet} {
    gap: 100px;
  }
  @media ${device.laptop} {
    gap: 150px;
  }
`;

const ProjectHero = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`;
const Title = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 10vw;
  color: black;
  z-index: 2;
  justify-self: center;

  overflow: hidden;
  span {
    position: relative;
    overflow: hidden;
  }
  height: max-content;

  @media ${device.tablet} {
    padding-top: 120px;
    margin-left: 50px;
    justify-self: unset;
  }
  @media ${device.laptop} {
    padding-top: 256px;
    margin-left: 100px;
  }
`;

const MainImage = styled(Image)`
  width: 100%;
`;

const ProjectDescription = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;
const DescriptionTitle = styled.div`
  flex: 0 1 20%;
  font-size: max(20px, 2vw);
  color: black;
  font-weight: 600;
`;
const DescriptionText = styled.p`
  flex: 0 1 50%;
  font-size: max(16px, 1.4vw);
  color: black;
`;

export default ProjectPageTemplate;
