import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { ContentfulProject } from 'types';
import ProjectPageTemplate from 'components/templates/ProjectPage';
import SEO from 'components/SEO';
import CustomCursor from 'components/CustomCursor';

interface Props {
  contentfulProject: ContentfulProject;
}

function Project({ data }: PageProps<Props>) {
  const pageData = data.contentfulProject;

  //TODO: do switch statement for seo
  const seo = {
    metaDescription: 'Project Page',
    metaTitle: 'Project',
  };

  return (
    <>
      <SEO lang="en" seo={{ ...seo }} />
      <CustomCursor />
      <ProjectPageTemplate {...pageData} />
    </>
  );
}

export default Project;

export const query = graphql`
  query Project($id: String) {
    contentfulProject(id: { eq: $id }) {
      mainImage {
        gatsbyImageData
      }
      previewImage {
        gatsbyImageData
      }
      title
      fullProjectName
      projectDescription {
        projectDescription
      }
      components {
        ... on ContentfulComponentFeatureImagesGrid {
          id
          contentful_id
          internal {
            type
          }
          images {
            gatsbyImageData
          }
        }
        ... on ContentfulComponentFeatureImages {
          id
          internal {
            type
          }
          leftImage {
            gatsbyImageData
          }
          rightImage {
            gatsbyImageData
          }
        }
        ... on ContentfulComponentFeatureImage {
          id
          internal {
            type
          }
          text {
            text
          }
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
