import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Image from 'components/Image';
import styled from 'styled-components';
import { device } from 'styles/theme';

export type FeatureImageProps = {
  id: string;
  internal: {
    type: 'ContentfulComponentFeatureImage';
  };
  image: {
    gatsbyImageData: IGatsbyImageData;
  };
  text?: {
    text: string;
  };
};

function FeatureImage({ image, text }: FeatureImageProps) {
  if (text) {
    return (
      <Container>
        <Image {...image} />
        <FeatureText>{text.text}</FeatureText>
      </Container>
    );
  } else {
    return <Image {...image} />;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.tablet} {
    flex-direction: row;
    gap: 32px;
    ${Image} {
      flex: 0 1 50%;
    }
  }
`;

const FeatureText = styled.p`
  font-size: max(16px, 1.4vw);
  line-height: 120%;
  @media ${device.tablet} {
    flex: 0 1 50%;
    padding-top: 10vw;
  }
`;
export default FeatureImage;
